import React from 'react';
import { graphql } from 'gatsby';
import { Box, Text, SadFaceIcon, HeadingText } from 'shared/components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Site } from '../types';

type Props = {
  data: {
    site: Site;
  };
  location: Location;
};

const NotFoundPage = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle} showNav={false}>
      <Box my={8} css={{ textAlign: 'center' }}>
        <SEO title="404: Not Found" />
        <HeadingText mb={6}>Not Found</HeadingText>
        <SadFaceIcon />
        <Text as="p" mt={4}>
          This page does not exist.
        </Text>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
